<template>
<transition name="fade">
<div class="itemoptions" @click="console">

<div v-if="!options_filtered.length">(この商品にオプションはありません)</div>
<!--
<br>
	現在の選択値:{{values}}<br>-->
<ul>
	<li class="optionlist" v-for="option,i in options_filtered" :class="[{selected:option_selected(i)},option.fields.typetag.value.split(',').join(' ')]" :key="i" :data-index="i">
		<dl>
			<dt><p><span class="require" v-if="option.fields.require.value=='on'">必須</span> {{option.fields.title.value}}</p></dt>

			<dd v-if="option.fields.type.value=='textarea'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
				<textarea :placeholder="option.fields.placeholder.value" @change="changeVal($event,i)" :value="getVal(i,0)"></textarea>
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>
			</dd>

			<dd v-if="option.fields.type.value=='text'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
				<input :placeholder="option.fields.placeholder.value" type="text" @change="changeVal($event,i)" :value="getVal(i,0)" />
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>
			</dd>

			<dd v-if="option.fields.type.value=='select'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
				<select name="" id="" @change="changeVal($event,i)" :value="getVal(i)">
					<option value="" v-if="option.fields.require.value!='on'">選択しない</option>
					<option :value="s" v-for="selector,s in option.fields.selector.subfields" :key="s">{{selector.name.value}}</option>
				</select>
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>
			</dd>


			<dd v-if="option.fields.type.value=='check'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
			<div><div class="option_child" v-for="selector,s in option.fields.selector.subfields" :key="s" :class="{selected:option_selected_child(i,s)}">
			<label><input class="zoomcheck" :name="'check'+i" @change="changeVal($event,i)" type="checkbox" :checked="getVal(i).indexOf(s)!=-1" :value="s"/> {{selector.name.value}}</label>
			<p class="option_info">{{selector.info.value}}</p>
			</div></div>
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>
			</dd>
			
			<dd v-if="option.fields.type.value=='radio'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
			<div>
			<div class="option_child" v-for="selector,s in option.fields.selector.subfields" :key="s" :class="{selected:option_selected_child(i,s)}">
			<label>
			
			<input class="zoomcheck" :name="'radio'+cartIndex+'-'+i" @change="changeVal($event,i)" :checked="getVal(i).indexOf(s)!=-1" type="radio" :value="s"/> {{selector.name.value}}</label>
			<p class="option_info">{{selector.info.value}}</p>
			</div></div>
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>
			</dd>

			
			<dd v-if="option.fields.type.value=='item' && option.fields.multiple.value=='select'">
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
			
			<select @change="changeVal($event,i)" :value="getVal(i)">
				<option value="" v-if="option.fields.require.value!='on'">選択しない</option>
				<option :value="s" v-for="selector,s in option.fields.items.subfields" :key="s">{{selector.name.value?selector.name.value:selector.item.textvalue}}</option>
			</select>
			
			<div class="inline" v-for="selector,s in option.fields.items.subfields" :key="s" >
				<label class="unit" v-if="option_selected_child(i,s)">
			<span v-if="selector.max.value!==''">
			数量：<select @change="setunit(i,s,$event.target.value)">
				<option :selected="getunit(i,s)==v" :value="v" v-for="v,s2 in selector.max.value-0" :key="s2">{{v}}</option>
			</select></span>
			<span v-if="selector.max.value!=='' && selector.max.value==0">在庫切れ</span>
			</label>
			<p class="option_info">{{selector.info.value}}</p>
			</div>

			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>

			</dd>
			<dd v-if="option.fields.type.value=='item' && option.fields.multiple.value=='add'">
			<div class="img"><img v-if="option.fields.image.url" :src="option.fields.image.url" alt=""></div>
			<div class="option_description" v-html="htmlformat(option.fields.info.value)"></div>
			<div class="option_childs">
			<div class="option_child" v-for="selector,s in option.fields.items.subfields" :key="s" :class="{selected:option_selected_child(i,s)}">
			<div :class="{disabled:selector.max.value!=='' && selector.max.value==0}">
				<label>
			
			<input :disabled="selector.max.value!=='' && selector.max.value==0 && getVal(i).indexOf(s)==-1" class="zoomcheck" @change="changeVal($event,i)" :checked="getVal(i).indexOf(s)!=-1" type="checkbox" :value="s"/> {{selector.name.value?selector.name.value:selector.item.textvalue}}
			</label>
			
			<p v-if="selector.max.value!=='' && selector.max.value==0">在庫切れ</p>
			<p v-else class="price">＋{{selector.record.price.value}}円<span>(税込)</span></p>


			<label class="unit">
			<span v-if="selector.max.value!=='' && selector.max.value>1 && option_selected_child(i,s)">数量:

			<select @change="setunit(i,s,$event.target.value)">
				<option :selected="getunit(i,s)==v" :value="v" v-for="v,s2 in selector.max.value-0" :key="s2">{{v}}</option>
			</select>
			
			</span>
			<span class="stockview">{{selector.stock.value}}</span>
			<span class="stock_sub" v-html="selector.stock_sub.value"></span>
			
			</label>
			</div>
			</div></div>
			<div class="option_description2" v-html="htmlformat(option.fields.desription.value)"></div>

			
			</dd>


		</dl>
          <inlineError :name="'items-'+cartIndex+'-option'+option.ID" :errors="posterrors" />

	</li>
</ul>

</div>
</transition>
</template>


<style lang="scss" scoped>
$sp: 680px; // スマホ
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
.itemoptions{
}
.itemoptions ul{
	padding:0;
	list-style:none;
}
.itemoptions ul li{
	border-bottom:solid 3px #CB3024;
		@include sp{
			margin-top:3.5em !important;
		}
}
.itemoptions ul li.selected{
	background:#eff2ff;
}
.itemoptions{
}
.itemoptions dl{
	display: flex;
	flex-direction: row;
	margin:0;
}
.itemoptions dl dt{
    word-break: break-all;
	padding: 1em;
	border-right:solid 3px #CB3024;
	width: 15em;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	.require{
		background: red;
		color: #fff;
		border-radius: .4em;
		display: inline-block;
		padding: .1em .4em;
		font-size: .8em;
		margin-right: .5em;
	}
}
.itemoptions dl dd{
    width: 100%;
	padding: 2em;
	.option_childs{

	}
	.option_child{
		flex-grow: 10;
		margin-top: 2em;
	}
	.option_child:first-child{
		margin-top: 0;
	}
	.option_description{
		margin-bottom: 1em;
		font-size: .75em;
	}
	.option_description2{
		margin-top: 1em;
		font-size: .75em;
		&::v-deep img{
			max-width: 100%;
		}
	}
}

.itemoptions dl dd{
.option_info{
	font-size: .8em;
}
.option_child.selected{
	font-weight:bold;
}
}
.itemoption_error{
	margin:0 0 .5em 0 ;
	background:yellow;
	padding:.3em .7em;
}
.inline{
	display: inline-block;
}
.optionlist{
	&.deliver1{
		position: relative;
		&::before{
			content:"小物商品";
			color: #fff;
			position: absolute;
			background: url("../assets/images/optionbar1.png") no-repeat right center /cover  ;
			padding: 0 3em 0 2em;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			top: -30px;
			@include sp{
				top: -50px;
			}
			left: -10px
		}
	}
	&.deliver1B{
		position: relative;
		&::before{
			content:"※1点まで追加送料無し";
			font-size: .8em;
			color: #fff;
			position: absolute;
			background: url("../assets/images/optionbar1.png") no-repeat right center /cover ;
			padding: 0 3em 0 6.5em;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			top: -30px;
			@include sp{
				top: -50px;
			}
			left: -10px
		}
		&::after{
			content:"大型商品";
			color: #fff;
			position: absolute;
			padding: 0 3em 0 1em;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			top: -30px;
			@include sp{
				top: -50px;
			}
			left: -10px
		}
	}
	&.deliver2{
		position: relative;
		border: solid .3em #1F376B;
		dl dt {
			border-bottom: solid .2em #1F376B;
			p { 
			border-bottom: solid .3em #1F376B;
			}
		}
		&::before{
			content:"宅急便対応";
			color: #fff;
			position: absolute;
			background: url("../assets/images/optionbar2.png") no-repeat right center /cover  ;
			padding: 0 3em 0 2em;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			top: -30px;
			@include sp{
				top: -50px;
			}
			left: -10px;
		}
	}
}
</style>

<script>

import inlineError from './inlinError.vue';
export default  {
	components:{inlineError},
	data:function(){
		return {
			//options:"",
			values:[],
			errors:[]

	}},
	computed:{
	},
	watch:{
		//valuesはこのコンポーネントでここに初期化されているため他のコンポーネントで変更されても反映されていない
		values:{
                handler:function(){
					if(typeof this.record=="undefined")return;
					console.log("オプションの値が変更されたのでフィールドにセット");

					//親への変更の返却時は、このコンポーネントで表示対象のオプション(this.options_filtered)の分だけを書き換えて上書きすること
					//直接値を書きかえるのではなく、コンポーネントにあらかじめ用意した値書き換え用のcomputeを使用してセットする。
					let options_filtered_ids=[];
					for(var o=0;o<this.options_filtered.length;o++){
						options_filtered_ids.push(this.options_filtered[o].ID)
					}

					console.log("グローバルデータは",this.record.fields.items.subfields[this.cartIndex].options.value)
					let parentValues=this.record.fields.items.subfields[this.cartIndex].options.value;

					//console.log(JSON.parse(this.$parent.value))
					if(!parentValues){parentValues=[];}else{parentValues=JSON.parse(parentValues)}
					console.log("同じものをパース、配列か",typeof parentValues,parentValues)
					for(var i=0;i<this.values.length;i++){
						let foundValue=false;	
						//編集中のフィールドの場合のみ、親のデータを検索し、データがあれば更新、無ければ追加
							console.log("データは編集対象？",options_filtered_ids,this.values[i].ID)
						if(options_filtered_ids.indexOf(this.values[i].ID)!==-1){
							console.log("編集対象です")
							for(var i2=0;i2<parentValues.length;i2++){
								if(parentValues[i2].ID==this.values[i].ID){
									parentValues[i2]=this.values[i];
									console.log("データはすでにあった")
									foundValue=true;
								}
							}
							//親に未代入だった場合は最後部に追加
							if(!foundValue){
								console.log("データはまだなかった",this.values[i])
								console.log(parentValues);
								parentValues.push(this.values[i]);
								foundValue=true;
							}
						}
					}
					console.log("this.$parent.valueに代入",parentValues);
					this.$parent.value=parentValues;
					this.$set(this.record.fields.items.subfields[this.cartIndex].options,"value",JSON.stringify(parentValues));
					//this.setSession("itemoption_values",this.values,true);
					//this.setSession("record",this.record,true);
					//console.log('各値でvaluesが更新されたためthis.recordを保存',this.record);
				
                },
                deep: true,
            }
	},
    props:[
		"options_filtered",
		"cartIndex",
		"posterrors"
    ],
    created:function(){
		//console.log(this.cartIndex,this.record)
		//this.values=this.setSession("itemoption_values",[],true);
		var optionValue=this.record.fields.items.subfields[this.cartIndex].options.value;
		if(optionValue)this.values=JSON.parse(optionValue);
		
		//console.log("初期値をセット開始,保存済み値は：",this.data);

		//var itemID=this.record._parent.subfields[this.record._index].item.value;
		//var arg={ID:itemID}
		//fetch("/admin/api/load_options",{method:'POST',headers: {'Content-Type': 'application/json'},body:JSON.stringify(arg)}).then(function(response){return response.json();}).then(function(data){
		//this.options_filtered=data;

		//未セットのデータは初期値をセット(選択必須の場合のみ、先頭のオプションを選択しておく)
		for(var i=0;i<this.options_filtered.length;i++){
			//console.log("--値の",this.values)
			//テキスト入力型以外はすべて該当
			let matched=false;
			if(["text","textarea"].indexOf(this.options_filtered[i].fields.type.value)==-1 && this.options_filtered[i].fields.require.value=="on"){
				//console.log("getVal",this.getVal(i))
				//value..オプションの保存する値
				//label..オプションの表示上の値
				//unit..オプションの数量
				if(this.getVal(i)===''||this.getVal(i).length===0){
                    matched=true;
				let tmp=this.getdefVal(i,0)
                    //console.log("ついかするの",tmp,this.getVal(i),this.getVal(i).length)
				this.values.push({
					ID:this.options_filtered[i].ID,
					values:[{value:tmp.value,label:tmp.label,unit:1}]
                });
                }
			}
			else if(["text","textarea"].indexOf(this.options_filtered[i].fields.type.value)!==-1){
				console.log("テキストの入力値",this.getValDefined(i));
                if(!this.getValDefined(i)){
					this.values.push({
						ID:this.options_filtered[i].ID,
						values:[]
					});
				}
			}
			if(!matched){/*
				let tmp=this.getdefVal(i,0)
                console.log("ついかするの2",tmp,this.getVal(i))
				this.values.push({
					ID:this.options_filtered[i].ID,
					values:[{value:tmp.value,label:tmp.label,unit:1}]
                });*/
			}
		}

		//}.bind(this))


      //alert(1)
    },
    methods:{
		console:function(){console.log("this.record(itemoption.vue):",this.record)},
		error_merged:function(){
			let errors=this.errors;
			for(let i=0;i<this.options_filtered.length;i++){
				if(typeof this.posterrors["field_items-"+(this.cartIndex)+"-option"+this.options_filtered[i].ID]!="undefined"){
					errors[i]=this.posterrors["field_items-"+(this.cartIndex)+"-option"+this.options_filtered[i].ID];
				}
			}
			return errors;
		},
		//該当のオプションの内に選ばれているものがあればtrue、選ばれていなければfalse
		option_selected:function(i){
			var option=this.options_filtered[i];
			for(var i2=0;i2<this.values.length;i2++){
				if(this.values[i2].ID==option.ID){
					if(this.values[i2].values.length)return true;
					break;
				}
			}
			return false;
		},
		//該当のオプションの内の選択肢が現在選ばれていればtrue、選ばれていなければfalse
		option_selected_child:function(i,s){
			var option=this.options_filtered[i];
			let val=this.getdefVal(i,s)
			for(var i2=0;i2<this.values.length;i2++){
				if(this.values[i2].ID==option.ID){
					for(var i3=0;i3<this.values[i2].values.length;i3++){
						if(this.values[i2].values[i3].value==val.value){
							return true;
						}
					}
				break;
				}
			}
			return false;
		},
		setunit:function(i,s,v){
			
			var option=this.options_filtered[i];
			let val=this.getdefVal(i,s)
			for(var i2=0;i2<this.values.length;i2++){
				if(this.values[i2].ID==option.ID){
					for(var i3=0;i3<this.values[i2].values.length;i3++){
						if(this.values[i2].values[i3].value==val.value){
							this.$set(this.values[i2].values[i3],"unit",v);
							break;
						}
					}
				break;
				}
			}
		},
		getunit:function(i,s){
			let data=this.getVal(i,s,true);
			if(typeof data.unit=="undefined"){
				return 1;
			}
			return data.unit;
		},
		//オプションの値のコンテナーが1つでも存在するかだけを返す
		getValDefined:function(index){
			var option=this.options_filtered[index];
			for(var i=0;i<this.values.length;i++){
				if(this.values[i].ID==option.ID){
					//console.log("=---------------option",index,this.values[i]);
					return true;
				}
			}
			return false;
		},
		getVal:function(index,valueIndex,returnData){
			//console.log("値get,",this.values[index])
			if(typeof returnData=="undefined")returnData=false;
			var option=this.options_filtered[index];
			var optionVals=[];
			if(option.fields.type.value=="item"){
				for(let i=0;i<option.fields.items.subfields.length;i++){
					optionVals.push(option.fields.items.subfields[i].item.value);
				}
			}else{
				for(let i=0;i<option.fields.selector.subfields.length;i++){
					optionVals.push(option.fields.selector.subfields[i].name.value);
				}
			}
			for(var i=0;i<this.values.length;i++){
				if(this.values[i].ID==option.ID){//console.log("ここでしょ2",option.ID,this.values[i].values)
					
					if(!this.values[i].values.length&&!returnData){
						if(returnData)return this.values[i];
						return "";
					}
					//入力型の場合はそのまま返す
					else if(["text","textarea"].indexOf(option.fields.type.value)!=-1){//console.log("ここでしょ",this.values[i].values[0])
						if(returnData)return this.values[i].values[0];
						return this.values[i].values[0].value;
					}
					//一意型の選択値の場合は選択値が含まれている場合のみ選択値を返す
					else if(["select"].indexOf(option.fields.type.value)!=-1){
						let foundIndex=optionVals.indexOf(this.values[i].values[0].value);
						if(foundIndex!=-1){
							if(returnData)return this.values[i].values[0];
							return foundIndex;
						}
						return "";
					}
					
					let rtn=[];
					for(var s=0;s<this.values[i].values.length;s++){
						let foundIndex=optionVals.indexOf(this.values[i].values[s].value);
						if(foundIndex!=-1){
							rtn.push(foundIndex);
							if(foundIndex==valueIndex && returnData)return this.values[i].values[s];
						}
					}/**/
					
					if(returnData)return {};
					return rtn;
					//return this.values[i].values[valueIndex].value;
				}
			}
			if(["text","textarea","select"].indexOf(option.fields.type.value)==-1){
				return [];
            }
            return "";
		},
		getdefVal:function(index,valueIndex){
			var option=this.options_filtered[index];
			var rtn={};
			if(["check","radio","select"].indexOf(option.fields.type.value)!==-1){
				rtn.label=rtn.value=option.fields.selector.subfields[valueIndex].name.value;
				rtn.unit=this.getunit(index,valueIndex);
			}
			else if(["item"].indexOf(option.fields.type.value)!==-1){
				rtn.value=option.fields.items.subfields[valueIndex].item.value;
				rtn.label=option.fields.items.subfields[valueIndex].item.textvalue;
				rtn.unit=this.getunit(index,valueIndex);
			}
			return rtn;
		},
		changeVal:function(e,index){
			var multiple=false;
			var option=this.options_filtered[index];
			var unit=1;
			var valueIndex=e.target.value;
			var checked=!e.target.value?false:e.target.checked;
			var value=e.target.value;
			var label=e.target.value;var tmp={};

			//テキスト入力以外は、選択値に対してその表示値などを読み込む
			//チェック型でチェックされている場合または値が入力されている場合
			if(checked||value){
				if(["check","radio","select","item"].indexOf(option.fields.type.value)!==-1){
					tmp=this.getdefVal(index,valueIndex);
					label=tmp.label;
					value=tmp.value;
					unit=tmp.unit;
				}
			}

			if("check"==option.fields.type.value || ("item"==option.fields.type.value && "add"==option.fields.multiple.value)){
				multiple=true;
			}
			
			//一意の値のフィールドの場合は、削除して追加
			if(!multiple){
				//console.log("")
				let overwritten=false;console.log("aaa",this.values)
				for(var i=0;i<this.values.length;i++){
					if(this.values[i].ID==option.ID){
						
						if(checked||value){
							overwritten=true;
							console.log(i+"番目を上書きである",overwritten)
							this.values[i].values.splice(0, 1,{value:value,label:label,unit:unit});
						}else{
							this.values[i].values.splice(0, 1);
							//this.values.splice(i, 1);
						}
						
					//複数値のフィールドの場合、選択解除の場合、その値を含む場合はその値を削除
					//複数値のフィールドの場合、選択追加の場合、その値を含まない場合はその値を追加
					}
				}
				//上書きする値が無かった場合は追加
				if((checked||value)&&!overwritten){
					console.log("上書きではない",overwritten)
					this.values.push({
						ID:option.ID,
						values:[{value:value,label:label,unit:unit}]
					});
				}

			//複数値のフィールドの場合、選択解除の場合、その値を含む場合はその値を削除
			//複数値のフィールドの場合、選択追加の場合、その値を含まない場合はその値を追加
			}else{
				let matched=false;
				//this.valuesはこの商品の全てのオプションデータ？
				for(let i=0;i<this.values.length;i++){
					if(this.values[i].ID==option.ID){
						matched=true;
						let overwritten=false;
						//値は常に配列で複数指定可能な形式
						for(var i2=0;i2<this.values[i].values.length;i2++){
							//値が見つかった場合
							if(this.values[i].values[i2].value==value){
								//その値を更新
								if(checked){
									overwritten=true;
									this.values[i].values.splice(i2, 1,{value:value,label:label,unit:unit});
								}else{
									this.values[i].values.splice(i2,1);
								}
							}
						}
						//上書きする値が無かった場合は追加
						if(checked && !overwritten)this.values[i].values.push({value:value,label:label,unit:unit});
					}
				}
				if(!matched && checked){
					if(checked){
						this.values.push({
							ID:option.ID,
							values:[{value:value,label:label,unit:unit}]
						});
					}
				}
			}
			if(value=='' && option.fields.require.value){
				this.$set(this.errors,index,'入力は必須です');
				console.log("errors:",this.errors)
				return;
			}
			
			this.$set(this.errors,index,'');
		}
    }
}
</script>